import React from "react"
import PropTypes from 'prop-types'

import FacebookIcon from '../images/icon-facebook.svg'
import InstagramIcon from '../images/icon-instagram.svg'
import LinkedInIcon from '../images/icon-linkedin.svg'
import TwitterIcon from '../images/icon-twitter.svg'

import FacebookIconBlue from '../images/icon-facebook-blue.svg'
import InstagramIconBlue from '../images/icon-instagram-blue.svg'
import LinkedInIconBlue from '../images/icon-linkedin-blue.svg'
import TwitterIconBlue from '../images/icon-twitter-blue.svg'

const SocialLinks = ({color}) => (
    <ul className="social-networks">
        <li className="social-networks__item">
            <a href="https://www.facebook.com/GNPartecareyes/" target="_blank" rel="noopener noreferrer">
                <img
                    src={color === 'beige' ? FacebookIcon : FacebookIconBlue}
                />
            </a>
        </li>
        <li className="social-networks__item social-networks__item--insta">
            <a href="https://www.instagram.com/gnpartecareyes/" target="_blank" rel="noopener noreferrer">
                <img
                    src={color === 'beige' ? InstagramIcon : InstagramIconBlue}
                />
            </a>
        </li>
        {/* <li className="social-networks__item social-networks__item--linkedin">
            <a href="">
                <img
                    src={color === 'beige' ? LinkedInIcon : LinkedInIconBlue}
                />
            </a>
        </li> */}
        <li className="social-networks__item social-networks__item--twitter">
            <a href="https://twitter.com/artecareyes" target="_blank" rel="noopener noreferrer">
                <img
                    src={color === 'beige' ? TwitterIcon : TwitterIconBlue}
                />
            </a>
        </li>
    </ul>
)

SocialLinks.propTypes = {
    color: PropTypes.oneOf(['beige', 'blue']),
}

export default SocialLinks