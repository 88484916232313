import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import '../styles/layout.scss'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, gradient }) => {
  return (
    <>
      <Header gradient={gradient} />
      <article className="page-content">
        <main>{children}</main>
      </article>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  gradient: PropTypes.object,
}

export default Layout
