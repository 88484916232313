import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'

import ContactLinks from './contactLinks'
import SocialLinks from './socialLinks'

import FilmArtsImage from '../images/group-18.svg'
import CloseIcon from '../images/close_36px.svg'
import ExpandIcon from '../images/add.svg'
import HideIcon from '../images/minus.svg'

import { MENU_ITEMS } from '../constants'

const Menu = ({onClose}) => {
    const [indexOpen, setIndexOpen] = useState(-1);

    const onToggleMenu = (index) => {
        if (index === indexOpen) {
            setIndexOpen(-1)
        } else {
            setIndexOpen(index)
        }
    }

    const onClickMenuItem = (anchor) => {
        navigate(anchor)
        onClose()
    }
    return (
        <nav className="menu">
            <section className="menu__header">
                <Fade top >
                <img
                    className="header__logo"
                    src={FilmArtsImage}
                    alt='film and arts festival'
                    onClick={() => onClickMenuItem('/')}
                />
                </Fade>
                <button className="header__close-button" onClick={onClose}>
                    <img src={CloseIcon} alt="close menu" />
                </button>
            </section>
            <section className="menu__nav-items">
            
                {MENU_ITEMS.map((column, colIndex) => (
                    <div className="nav-container" key={column.title}>
                    <Fade cascade duration={2000}>
                        <h3 className="nav-container__title">
                            <Link to={column.link}>{column.title}</Link>
                            <button
                                className="nav-container__title-button"
                                onClick={() => onToggleMenu(colIndex)}
                            >
                                <img
                                    src={indexOpen === colIndex ? HideIcon : ExpandIcon} alt="hide/expand"
                                />
                            </button>
                        </h3>
                        </Fade>
                        <Fade cascade duration={2000}>
                        <ul className={`nav-container__list ${indexOpen !== colIndex ? 'nav-container__list--hide' : ''}`}>
                            {column.items.map((listItem, index) => (
                                <li className="nav-container__list-item" key={index}>
                                    <button className="list-item__button" onClick={() => onClickMenuItem(listItem.anchor)}>
                                        {listItem.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        </Fade>
                    </div>
                ))}
                
            </section>
            <section className="menu__footer">
                
                <Fade bottom duration={2000}>
                <ContactLinks color="blue" />
                </Fade>
                
                <Fade bottom duration={2000}>
                <SocialLinks color="blue" />
                </Fade>           
            </section>
        </nav>
    )
}

Menu.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default Menu