import React from 'react'
import PropTypes from 'prop-types'

const Button = ({type, onClick, children, disabled = false, buttonClass}) => (
    <button
        onClick={onClick}
        className={`button ${type === 'secondary' && 'button--secondary'} ${buttonClass}`}
        disabled={disabled}
    >
        <div className="button-decorator" />
        {children}
    </button>
)

Button.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Button