import ImageLineup from '../images/carteles/1.jpg'
import ImageLineup2x from '../images/carteles/1.jpg'
import ImageLineup3x from '../images/carteles/1.jpg'

import ImageLineupCopy from '../images/carteles/2.jpg'
import ImageLineupCopy2x from '../images/carteles/2.jpg'
import ImageLineupCopy3x from '../images/carteles/2.jpg'

import ImageLineupCopy2 from '../images/carteles/3.jpg'
import ImageLineupCopy22x from '../images/carteles/3.jpg'
import ImageLineupCopy23x from '../images/carteles/3.jpg'

import ImageLineupCopy3 from '../images/carteles/4.jpg'
import ImageLineupCopy32x from '../images/carteles/4.jpg'
import ImageLineupCopy33x from '../images/carteles/4.jpg'

import ImageLineupCopy4 from '../images/carteles/5.jpg'
import ImageLineupCopy42x from '../images/carteles/5.jpg'
import ImageLineupCopy43x from '../images/carteles/5.jpg'

import ImageLineupCopy5 from '../images/carteles/6.jpg'
import ImageLineupCopy52x from '../images/carteles/6.jpg'
import ImageLineupCopy53x from '../images/carteles/6.jpg'

import ImageCasita from '../images/casitas_flores.jpg'
import ImageCasita2 from '../images/casitas_flores.jpg'
import ImageCasita3 from '../images/casitas_flores.jpg'

import ImageNido from '../images/residencias_careyes_club.jpg'
import ImageNido2 from '../images/residencias_careyes_club.jpg'
import ImageNido3 from '../images/residencias_careyes_club.jpg'

import ImageBeachHouse from '../images/beach_club.jpg'
import ImageBeachHouse2 from '../images/beach_club.jpg'
import ImageBeachHouse3 from '../images/beach_club.jpg'

import ImageMainVillas from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-43-57.png'
import ImageMainVillas2 from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-43-57@2x.png'
import ImageMainVillas3 from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-43-57@3x.png'

import ImageMainCasitas from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-44-11.png'
import ImageMainCasitas2 from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-44-11@2x.png'
import ImageMainCasitas3 from '../images/captura-de-pantalla-2019-12-30-a-la-s-17-44-11@3x.png'

import ImageRoomVillas from '../images/hotel-5.png'
import ImageRoomVillas2 from '../images/hotel-5@2x.png'
import ImageRoomVillas3 from '../images/hotel-5@3x.png'

import ImageRoomCasitas from '../images/hotel-7.png'
import ImageRoomCasitas2 from '../images/hotel-7@2x.png'
import ImageRoomCasitas3 from '../images/hotel-7@3x.png'

import ImgCastles1 from '../images/castles-1_Img.jpg'
import ImgCastles2 from '../images/castles-2_Img.jpg'
import ImgCastles3 from '../images/castles-3_Img.jpg'
import ImgCastles4 from '../images/castles-4_Img.jpg'

import ImgCasitas1 from '../images/casitas_pools-1_Img.jpg'
import ImgCasitas2 from '../images/casitas_pools-2_Img.jpg'
import ImgCasitas3 from '../images/casitas_pools-3_Img.jpg'
import ImgCasitas4 from '../images/casitas_pools-4_Img.jpg'

import ImgNido1 from '../images/samadhi-Nido-1.jpg'
import ImgNido2 from '../images/samadhi-Nido-2.jpg'
import ImgNido3 from '../images/samadhi-Nido-3.jpg'

import ImgElCareyes1 from '../images/elcareyes-Elcareyes-1.jpg'
import ImgElCareyes2 from '../images/elcareyes-Elcareyes-2.jpg'
import ImgElCareyes3 from '../images/elcareyes-Elcareyes-3.jpg'

import ImgNidoAmor1 from '../images/sliderGalery/Slider/nido/nido1.jpg'
import ImgNidoAmor2 from '../images/sliderGalery/Slider/nido/nido2.jpg'
import ImgNidoAmor3 from '../images/sliderGalery/Slider/nido/nido3.jpg'
import ImgNidoAmor4 from '../images/sliderGalery/Slider/nido/nido4.jpg'
import ImgNidoAmor5 from '../images/sliderGalery/Slider/nido/nido5.jpg'
import ImgNidoAmor6 from '../images/sliderGalery/Slider/nido/nido6.jpg'
import ImgNidoAmor7 from '../images/sliderGalery/Slider/nido/nido7.jpg'

import ImgDragon1 from '../images/sliderGalery/Slider/dragon/dragon1.jpg'
import ImgDragon2 from '../images/sliderGalery/Slider/dragon/dragon2.jpg'
import ImgDragon3 from '../images/sliderGalery/Slider/dragon/dragon3.jpg'
import ImgDragon4 from '../images/sliderGalery/Slider/dragon/dragon4.jpg'
import ImgDragon5 from '../images/sliderGalery/Slider/dragon/dragon5.jpg'
import ImgDragon6 from '../images/sliderGalery/Slider/dragon/dragon6.jpg'

import ImgAries1 from '../images/sliderGalery/Slider/aries/aries-1.jpg'
import ImgAries2 from '../images/sliderGalery/Slider/aries/aries-2.jpg'
import ImgAries3 from '../images/sliderGalery/Slider/aries/aries-3.jpg'
import ImgAries4 from '../images/sliderGalery/Slider/aries/aries-4.jpg'
import ImgAries5 from '../images/sliderGalery/Slider/aries/aries-5.jpg'
import ImgAries6 from '../images/sliderGalery/Slider/aries/aries-6.jpg'

import ImgAltiplano1 from '../images/sliderGalery/Slider/altiplano/altiplano-1.jpg'
import ImgAltiplano2 from '../images/sliderGalery/Slider/altiplano/altiplano-2.jpg'
import ImgAltiplano3 from '../images/sliderGalery/Slider/altiplano/altiplano-3.jpg'
import ImgAltiplano4 from '../images/sliderGalery/Slider/altiplano/altiplano-4.jpg'
import ImgAltiplano5 from '../images/sliderGalery/Slider/altiplano/altiplano-5.jpg'

import ImgEscondida1 from '../images/sliderGalery/Slider/escondida/escondida-1.jpg'
import ImgEscondida2 from '../images/sliderGalery/Slider/escondida/escondida-2.jpg'
import ImgEscondida3 from '../images/sliderGalery/Slider/escondida/escondida-3.jpg'
import ImgEscondida4 from '../images/sliderGalery/Slider/escondida/escondida-4.jpg'
import ImgEscondida5 from '../images/sliderGalery/Slider/escondida/escondida-5.jpg'

import ImgPalomir1 from '../images/sliderGalery/Slider/palomir/palomir-1.jpg'
import ImgPalomir2 from '../images/sliderGalery/Slider/palomir/palomir-2.jpg'
import ImgPalomir3 from '../images/sliderGalery/Slider/palomir/palomir-3.jpg'
import ImgPalomir4 from '../images/sliderGalery/Slider/palomir/palomir-4.jpg'
import ImgPalomir5 from '../images/sliderGalery/Slider/palomir/palomir-5.jpg'

import ImgChacmool1 from '../images/sliderGalery/simple/chacmool/chacmool-1.jpg'
import ImgChacmool2 from '../images/sliderGalery/simple/chacmool/chacmool-2.jpg'

import ImgSelva1 from '../images/sliderGalery/simple/selva/selva-1.jpg'
import ImgSelva2 from '../images/sliderGalery/simple/selva/selva-2.jpg'

import ImgCandelabros1 from '../images/sliderGalery/simple/candelabros/candelabros-1.jpg'
import ImgCandelabros2 from '../images/sliderGalery/simple/candelabros/candelabros-2.jpg'

import ImgCarioca1 from '../images/sliderGalery/simple/carioca/carioca-1.jpg'
import ImgCarioca2 from '../images/sliderGalery/simple/carioca/carioca-2.jpg'
import ImgCarioca3 from '../images/sliderGalery/simple/carioca/carioca-3.jpg'

import ImgAzul1 from '../images/sliderGalery/simple/azul/azul-2.jpg'
import ImgAzul2 from '../images/sliderGalery/simple/azul/azul-3.jpg'

export const HOME = {
  title: 'Inicio',
  link: '/',
  items: [
    {
      anchor: '/#home-about',
      name: 'About',
    },
    {
      anchor: '/#home-sponsors',
      name: 'Patrocinadores',
    },
    {
      anchor: '/#home-collaborators',
      name: 'Colaboradores',
    },
  ],
}
export const PROGRAM = {
  title: 'Programa 2020',
  link: '/programa',
  items: [
    {
      anchor: '/programa#patterns',
      name: 'Patrones naturales',
    },
    // {
    //     anchor: '/programa#activities',
    //     name: 'Actividades por dia'
    // },
    // {
    //     anchor: '/programa#recommendations',
    //     name: 'Recomendaciones'
    // },
    {
      anchor: '/programa#map',
      name: 'Mapa de ubicación',
    },
  ],
}
export const HISTORY = {
  title: 'Historia',
  link: '/historia',
  items: [
    {
      anchor: '/historia#about',
      name: 'Historia',
    },
    {
      anchor: '/historia#tribute',
      name: 'Tributo TANE',
    },
    {
      anchor: '/historia#past-editions',
      name: 'Ediciones anteriores',
    },
    {
      anchor: '/historia#gallery',
      name: 'Galería',
    },
  ],
}

export const TICKETS = {
  title: 'Boletos',
  link: '/boletos',
  items: [
    {
      anchor: '/boletos#bracelet',
      name: 'Pulsera',
    },
    // {
    //     anchor: '/boletos#bracelet-vip',
    //     name: 'Pulsera VIP'
    // },
    {
      anchor: '/boletos#not-included',
      name: 'Qué no incluye',
    },
    {
      anchor: '/boletos#contact',
      name: 'Contacto',
    },
  ],
}

export const HOSTING = {
  title: 'Hospedaje',
  link: '/hospedaje',
  items: [
    {
      anchor: '/hospedaje#private-casita',
      name: 'Casita de Las Flores',
    },
    {
        anchor: '/hospedaje#nido',
        name: 'El Careyes Club'
    },
    {
        anchor: '/hospedaje#beach-club',
        name: 'Private Villas'
    },
    {
        anchor: '/hospedaje#contact',
        name: 'Contacto'
    },
  ],
}

export const MENU_ITEMS = [HOME, PROGRAM, HISTORY, TICKETS, HOSTING]

export const SCHEDULE = [
  {
    date: 'JUEVES 26 DE MARZO 2020',
    activities: [
      {
        time: '9:00 a 19:00',
        name: 'Llegada de los invitados.',
      },
      {
        time: '19:00 a 19:45',
        name: 'Anthropocene Sunset Show(Ojo de Venado).',
      },
      {
        time: '20:00 a 22:00',
        name: 'Proyección de Cine Especial (Ojo de Venado).',
      },
      {
        time: '22:00',
        name: 'Cena de inauguración (Casa Tigre del Mar).',
      },
      {
        time: '',
        name:'PRESENTADA POR GNP'
      }
    ],
  },
  {
    date: 'VIERNES 27 DE MARZO 2020',
    activities: [
      {
        time: '9:00 a 13:00',
        name: 'Sesiones de Yoga y Meditación (Casa Corazón).',
      },
      {
        time: '12:00 a 15:00',
        name: 'Careyes Talent Lab (Cine Paraíso)',
      },
      {
        time: '14:00',
        name: 'Comida VIP (40 pax).',
      },
      {
        time:'17:00 a 19:00',
        name:'Retrato de Familia (Cine Paraíso)'
      },
      {
        time:'18:30',
        name:'Anthropocene Sunset Show (Chac Mool)'
      },  
      {
        time: '19:30 a 19:45',
        name: 'Apertura de galerías (Plaza de los Caballeros).',
      },
      {
        time: '19:45 a 21:45',
        name: 'Entrega Tributo Tane y Proyección Estelar de Cine (Plaza de los Caballeros).',
      },
      {
        time: '22:00',
        name: 'Cena y Concierto (Cocodrilo Azul).',
      },
    ],
  },
  {
    date: 'SÁBADO 28 DE MARZO 2020',
    activities: [
      {
        time: '9:00 a 13:00',
        name: 'Sesiones de Yoga y Meditación(Casa Corazón).',
      },
      {
        time:'12:00 a 14:00',
        name:'Careyes Talent Lab (Cine Paraíso)'
      },
      {
        time: '12:00 a 17:00',
        name: 'Full Day Playa',
      },
      {
        time: '17:00 a 19:00',
        name: 'Función Privada 7 Días en la Habana (Cine Paraíso)',
      },
      {
        time: '19:00 a 20:00',
        name: 'Antropocene Sunset Show (Plaza de los Caballeros).',
      },
      {
        time: '20:00 a 21:00',
        name: 'Subasta de Arte (Plaza de los Caballeros).',
      },
      {
        time: '21:00',
        name: 'Cena de Cierre (Plaza de los Caballeros).',
      },
      {
        time: '',
        name:'PRESENTADA POR GNP'
      }
    ],
  },
  {
    date: 'DOMINGO 29 DE MARZO 2020',
    activities: [
      {
        time: '20:30',
        name: 'Función para niños.',
      },
    ],
  },
]

export const PAST_EDITIONS = [
  {
    title: 'MÚSICA',
    imageSet: [ImageLineup, ImageLineup2x, ImageLineup3x],
  },
  {
    title: 'ARTE CONTEMPORÁNEO',
    imageSet: [ImageLineupCopy, ImageLineupCopy2x, ImageLineupCopy3x],
  },
  {
    title: 'CINE INTERNACIONAL',
    imageSet: [ImageLineupCopy2, ImageLineupCopy22x, ImageLineupCopy23x],
  },
  {
    title: 'DIRECCIÓN',
    imageSet: [ImageLineupCopy3, ImageLineupCopy32x, ImageLineupCopy33x],
  },
  {
    title: 'CINE NACIONAL',
    imageSet: [ImageLineupCopy4, ImageLineupCopy42x, ImageLineupCopy43x],
  },
  {
    title: 'CINE NACIONAL',
    imageSet: [ImageLineupCopy5, ImageLineupCopy52x, ImageLineupCopy53x],
  },
]

export const HOSTING_ROOMS = [
  {
    images: [ImageCasita, ImageCasita2, ImageCasita3],
    header: 'Casitas de Las Flores,',
    subheader: 'Casitas de 1 a 3 habitaciones con vista al mar',
    paragraph:
      'Ubicadas sobre la colina de Playa Rosa está Casitas de las Flores una postal multicolor en Careyes, Casitas de 1 a 4 habitaciones con vista al mar, terraza privada y a unos pasos de la playa, servicio de housekeeping y Concierge.',
    id: 'private-casita',
    link: '/private-casita',
    size: 'double',
  },
  {
      images: [ImageNido, ImageNido2, ImageNido3],
      header: 'Residencias en El Careyes Club',
      subheader: '1 - 5 habitaciones, playa privada, albercas y restaurante.',
      paragraph: 'Las residencias cuentan con un ambiente y estilo contemporáneos para satisfacer las expectativas de viaje de los huéspedes y proporcionar un alojamiento de alto nivel, El Careyes Club & Residences cuenta con 5 albercas, un restaurante, playa privada y varias amenidades.',
      id: 'nido',
      link: '/nido',
      size: 'half'
  },
  {
      images: [ImageBeachHouse, ImageBeachHouse2, ImageBeachHouse3],
      header: 'Private Villas',
      subheader: '3 - 6 habitaciones, vista al mar, alberca privada y staff',
      paragraph: 'Las villas privadas de Careyes ofrecen vistas panorámicas al océano, con terrazas y jardines que enmarcan el estilo original de Careyes, las villas cuentan con alberca privada, terrazas y personal que brinda un servicio inigualable.',
      id: 'beach-club',
      link: '/beach-club',
      size: 'half'
  },
]

export const VILLAS_ROOM = {
  id: 'villas',
  name: 'Villas',
  bedrooms: '2/6 Bedrooms',
  price: 'starting at $1,800/night',
  mainImage: [ImageMainVillas, ImageMainVillas2, ImageMainVillas3],
  roomImage: [ImageRoomVillas, ImageRoomVillas2, ImageRoomVillas3],
}

export const CASITAS = {
  id: 'casitas',
  name: 'Casitas',
  side: 'without pools',
  bedrooms: '22/4 Bedrooms',
  price: 'starting at $660/night',
  mainImage: [ImageRoomCasitas, ImageRoomCasitas2, ImageRoomCasitas3],
  roomImage: [ImageMainCasitas, ImageMainCasitas2, ImageMainCasitas3],
}

// Nuevas casitas para CASITAS Careyes
//Slider
export const NIDO_AMOR = {
  id: 'nidoAmor',
  name: 'Nido de Amor',
  side: 'Villa privada con 3 habitaciones',
  bedrooms:
    ' 3 habitaciones con cama King, 2 palapas Roof Terrace y Alberca Infinity',
  price: [''],
  imageList: [
    ImgNidoAmor1,
    ImgNidoAmor2,
    ImgNidoAmor3,
    ImgNidoAmor4,
    ImgNidoAmor5,
    ImgNidoAmor6,
    ImgNidoAmor7,
  ],
}
export const DRAGON = {
  id: 'dragon',
  name: 'Casa Dragon',
  side: 'Villa privada con 4 habitaciones',
  bedrooms: 'Roof Terrace, Sun deck, Alberca Infinity ',
  price: [''],
  imageList: [
    ImgDragon1,
    ImgDragon2,
    ImgDragon3,
    ImgDragon4,
    ImgDragon5,
    ImgDragon6,
  ],
}

export const ARIES = {
  id: 'aries',
  name: 'Casa Aries',
  side: 'Villa privada con 4 habitaciones ',
  bedrooms: 'Roof Terrace, sun deck, alberca Infinity ',
  price: ['por definir'],
  imageList: [ImgAries1, ImgAries2, ImgAries3, ImgAries4, ImgAries5, ImgAries6],
}
export const ALTIPLANO = {
  id: 'altiplano',
  name: 'Casa Altiplano',
  side: 'Villa privada con 5 habitaciones',
  bedrooms: 'Vista panoramica al mar, sun deck, comedor abierto y salas',
  price: [''],
  imageList: [
    ImgAltiplano1,
    ImgAltiplano2,
    ImgAltiplano3,
    ImgAltiplano4,
    ImgAltiplano5,
  ],
}
export const ESCONDIDA = {
  id: 'escondida',
  name: 'Casa Escondida',
  side: 'Villa privada con 4 habitaciones',
  bedrooms: 'Vista al mar,cComedor abierto y salas',
  price: [''],
  imageList: [
    ImgEscondida1,
    ImgEscondida2,
    ImgEscondida3,
    ImgEscondida4,
    ImgEscondida5,
  ],
}

export const PALOMIR = {
  id: 'palomir',
  name: 'Casa Palomir',
  side: 'Villa privada con 5 habitaciones ',
  bedrooms: 'Vista al mar, comedor abierto y salas ',
  price: [''],
  imageList: [ImgPalomir1, ImgPalomir2, ImgPalomir3, ImgPalomir4, ImgPalomir5],
}
//Normal
export const CARIOCA = {
  id: 'carioca',
  name: 'Carioca',
  side: '',
  bedrooms:
    '2 habitaciones con cama king size • 1 habitación con cama doble • 1 habitación con 3 camas sencillas y 1 sillón cama • 3 salas • 2 comedores • Alberca Infinity',
  // price: [''],
  mainImage: [ImgCarioca3, ImgCarioca2, ImgCarioca3],
  roomImage: [ImgCarioca1, ImgCarioca1, ImgCarioca2],
}

export const AZUL = {
  id: 'azul',
  name: 'Casa Azúl',
  side: '',
  bedrooms: '6 Bedrooms',
  price: [''],
  mainImage: [ImgAzul1, ImgAzul2],
  roomImage: [ImgAzul2, ImgAzul1],
}

export const SELVA = {
  id: 'selva',
  name: 'Casa Selva',
  side: '',
  bedrooms: '7 Bedrooms',
  price: [''],
  mainImage: [ImgSelva1, ImgSelva2],
  roomImage: [ImgSelva2, ImgSelva1],
}

export const CANDELABROS = {
  id: 'candelabros',
  name: 'Casa Candelabros',
  side: '',
  bedrooms: '5 Bedrooms',
  price: [''],
  mainImage: [ImgCandelabros1, ImgCandelabros2],
  roomImage: [ImgCandelabros2, ImgCandelabros1],
}

export const CHACMOOL = {
  id: 'chac-mool',
  name: 'Casa Chac Mool',
  side: '',
  bedrooms: '6 Bedrooms',
  price: [''],
  mainImage: [ImgChacmool1, ImgChacmool2],
  roomImage: [ImgChacmool2, ImgChacmool1],
}

// Nuevas casitas para CASITAS Careyes
export const CASTLES = {
  id: 'castles',
  name: 'Castles',
  bedrooms: '6/8 Bedrooms',
  price: ['starting at $4,200/night'],
  imageList: [ImgCastles1, ImgCastles2, ImgCastles3, ImgCastles4],
}

export const CASITAS_POOLS = {
  id: 'casitas-pools',
  name: 'Casitas',
  side: 'with pools',
  bedrooms: '2/4 Bedrooms',
  price: ['starting at $660/night'],
  imageList: [ImgCasitas1, ImgCasitas2, ImgCasitas3, ImgCasitas4],
}

export const TENT = {
  id: 'tent',
  name: 'Tent',
  bedrooms: 'beds',
  price: [
    '2 Person Yurt  $2,900 (price per person)',
    '4 Person Yurt  $2,600 (price per person)',
  ],
  imageList: [ImgNido1, ImgNido2, ImgNido3],
}

export const BEACH_CLUB = {
  id: 'beach-club',
  name: 'Amenities',
  bedrooms: '1/3 Bedrroms',
  price: ['starting at $360/night'],
  pool: '5 infinity pools',
  tennis: '2 tennis courts',
  food: 'Restaurant / Bar',
  imageList: [ImgElCareyes1, ImgElCareyes2, ImgElCareyes3],
}

export const DAYS_PLACEHOLDERS = [
  {
    textBold: 'JUEVES 26',
    textNormal: 'DE MARZO 2020',
  },
  {
    textBold: 'VIERNES 27',
    textNormal: 'DE MARZO 2020',
  },
  {
    textBold: 'SÁBADO 28',
    textNormal: 'DE MARZO 2020',
  },
  {
    textBold: 'DOMINGO 29',
    textNormal: 'DE MARZO 2020',
  },
]

export const HISTORY_PLACEHOLDERS = [
  {
    textBold: '2019',
    textNormal: 'JEAN MARC VALLÈE',
  },
  {
    textBold: '2018',
    textNormal: 'JR',
  },
  {
    textBold: '2017',
    textNormal: 'LEE DANIELS',
  },
  {
    textBold: '2016',
    textNormal: 'DIEGO LUNA',
  },
  {
    textBold: '2015',
    textNormal: 'GUILLERMO ARRIAGA',
  },
  {
    textBold: '2014',
    textNormal: 'MICHAEL NYMAN',
  },
  {
    textBold: '2013',
    textNormal: 'CARLOS CUARÓN',
  },
]
