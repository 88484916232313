import React from 'react'

import VivirLogo from '../images/group-102.svg'

import ContactLinks from './contactLinks'
import SocialLinks from './socialLinks'

const Footer = () => (
  <footer className="page-footer">
    <ContactLinks color="white" />
    <div
      style={{
        backgroundImage: `url(${VivirLogo})`,
        height: 26,
        width: 200,
        margin: '0 auto',
      }}
      className="page-footer__logo"
      alt="vivir es increible"
    />
    <SocialLinks color="beige" />
  </footer>
)

export default Footer
