import React from "react"
import PropTypes from 'prop-types'

import { Link } from "gatsby"
import Aviso from "../images/docs/aviso_privacidad.pdf";

const ContactLinks = ({color}) => (
    <div className="contact-links">
     {/*<Link to="#" className={`contact-links__text ${color === 'blue' ? 'contact-links__text--blue' : ''}`}>
            CONTACTO
</Link>*/}
        <a href={Aviso}  target="_blank" className={`contact-links__text ${color === 'blue' ? 'contact-links__text--blue' : ''}`}>
            AVISO DE PRIVACIDAD
        </a>
        <p className={`contact-links__text ${color === 'blue' ? 'contact-links__text--blue' : ''}`}>
            COPYRIGHT ©2020 ARTE CAREYES
        </p>
    </div>
)

ContactLinks.propTypes = {
    color: PropTypes.oneOf(['white', 'blue']),
}

export default ContactLinks