import React, { useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Menu from './menu'

import CareyesLogo from '../images/careyes-logo.svg';
import MenuIcon from '../images/elements/line.svg';

const Header = ({ gradient }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const goHome = () => {
    navigate('/')
  }

  return (
    <React.Fragment>
      <header className="page-header" style={gradient}>
        <img
          src={CareyesLogo}
          alt="careyes"
          className="page-header__icon"
          onClick={goHome}
        />
        <button
          className="page-header__menu-button"
          onClick={() => setIsMenuOpen(true)}
        >
          <img  src={MenuIcon} alt="menu" className="ham" />
          <img src={MenuIcon} alt="menu" className="ham medio" />
          <img src={MenuIcon} alt="menu" className="ham" />
        </button>
      </header>
      {isMenuOpen && <Menu onClose={() => setIsMenuOpen(false)} />}
    </React.Fragment>
  )
}

Header.propTypes = {
  gradient: PropTypes.object,
}

export default Header
